import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-agreement',
  standalone: true,
  imports: [],
  templateUrl: './customer-agreement.component.html',
  styleUrl: './customer-agreement.component.scss'
})
export class CustomerAgreementComponent {

}
