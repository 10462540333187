// export const adapter: EntityAdapter<Form> = createEntityAdapter<Form>();

import { createFeature, createReducer, on } from "@ngrx/store";
import { formAdapter, initialState } from "./form.state";
import * as FormActions from "./form.actions";

export const formReducer = createReducer(
    initialState,
    on(FormActions.selectForm, (state, { id }) => (
        { ...state, selectedBookId: id }
    )),
    on(FormActions.loadForms, FormActions.loadForm, (state) => (
        {
            ...state,
            isLoading: true,
            submittedSuccessfully: false,
            submitting: false,
        }
    )),

    on(FormActions.addForm, FormActions.updateForm, FormActions.deleteForm, FormActions.duplicateForm, (state) => (
        {
            ...state,
            submittedSuccessfully: false,
            submitting: true,
        }
    )),

    on(FormActions.loadFormsSuccess, (state, { data }) =>
        formAdapter.setAll(data, { ...state, isLoading: false })
    ),

    on(FormActions.loadFormSuccess, (state, { recordInfo }) => {
        if (recordInfo) {
            return formAdapter.upsertOne(recordInfo, { ...state, isLoading: false, submittedSuccessfully: false })
        }
        return state;
    }),


    on(FormActions.addFormSuccess, FormActions.duplicateFormSuccess, (state) => ({ ...state, submittedSuccessfully: true, submitting: false })),

    on(FormActions.updateFormSuccess, (state, { recordInfo }) => {
        if (recordInfo) {
            return formAdapter.updateOne({ id: recordInfo.id, changes: recordInfo }, { ...state, isLoading: false, submittedSuccessfully: true, submitting: false })
        }
        return state;
    }
    ),

    on(FormActions.deleteFormSuccess, (state, { id }) =>
        formAdapter.removeOne(id, { ...state, isLoading: false, submittedSuccessfully: true, submitting: false })
    ),

    on(FormActions.loadFormsFailed,
        FormActions.loadFormFailed,
        (state, { headers, errorlist }) =>
            ({ ...state, error: headers?.message ?? "Server Error", loading: false, errorlist })
    ),

    on(
        FormActions.addFormFailed,
        FormActions.duplicateFormFailed,
        FormActions.updateFormFailed,
        FormActions.deleteFormFailed,
        (state, { headers, errorlist }) =>
            ({ ...state, error: headers?.message ?? "Server Error", loading: false, errorlist, submittedSuccessfully: false, submitting: false })
    ),
);

export const formFeature = createFeature({
    name: 'formState',
    reducer: formReducer
});