import { Injectable } from '@angular/core';
import { env } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IDuplicateFormRequest, IForm, IFormDisplayConfig, IFormResponse, IFormsResponse, INewFormRequest, INewFormResponse } from './store/form.models';
import { IAPIErrorResponse, IAPIResponseHeaders } from '../../../models/base';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private formsAPIUrl = `${env.apiBaseUrl}/forms-service/forms`;

  constructor(private http: HttpClient) { }

  getForms(): Observable<IFormsResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/load`;
    return this.http.get<IFormsResponse>(url).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

  getForm(id: string): Observable<IFormResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/load/${id}`;
    return this.http.get<IFormResponse>(url).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

  addForm(form: INewFormRequest): Observable<INewFormResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/create`;
    return this.http.post<INewFormResponse>(url, form).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

  duplicateForm(form: IDuplicateFormRequest): Observable<INewFormResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/duplicate`;
    return this.http.post<INewFormResponse>(url, form).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

  updateForm(form: IForm): Observable<IFormResponse | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/update`;
    return this.http.put<IFormResponse>(url, form).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

  updateFormDisplayConfig(formConfig: IFormDisplayConfig): Observable<{ headers: IAPIResponseHeaders } | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/config/updatedisplayconfig`;
    return this.http.put<{ headers: IAPIResponseHeaders }>(url, formConfig).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }


  deleteForm(id: string): Observable<{ headers: IAPIResponseHeaders } | IAPIErrorResponse> {
    const url = `${this.formsAPIUrl}/delete`;
    return this.http.delete<{ headers: IAPIResponseHeaders }>(url, { body: { formId: id } }).pipe(
      catchError(error => of(error.error as IAPIErrorResponse))
    );
  }

}
