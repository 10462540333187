import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsLoggedIn } from '../store/auth.selectors';
import { EMPTY, map, mergeMap, of, take } from 'rxjs';

export const noAuthGuard: CanActivateFn = (route, state) => {

  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectIsLoggedIn).pipe(
    take(1),
    mergeMap(isLoggedIn => {
      if (isLoggedIn) {
        router.navigateByUrl('/');
        return EMPTY;
      }
      return of(!isLoggedIn);
    })
  );
};
