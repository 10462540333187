import { HttpErrorResponse, HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { AuthFacade } from '../store/auth.facade';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenStorageService = inject(TokenStorageService);
  const authFacade = inject(AuthFacade);


  const accessToken = tokenStorageService.getAccessToken();
  const refreshKey = tokenStorageService.getRefreshToken()

  if (accessToken) {

    // add conditions here to check if adding access token to the correct API requests

    req = req.clone({
      setHeaders: { Authorization: `Bearer ${accessToken}` },
    });
  }

  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors         
          console.error('Unauthorized request:', err);
          // You might trigger a re-authentication flow or redirect the user here
          if (refreshKey) {
            authFacade.logout({
              refreshKey
            });
          }
        } else {
          // Handle other HTTP error codes
          console.error('HTTP error:', err);
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    })
  );;
};

const handle401 = () => {

  const authFacade = inject(AuthFacade);
  const tokenStorageService = inject(TokenStorageService);
  console.log('handle401')

  // logout user
  authFacade.logout({
    refreshKey: tokenStorageService.getRefreshToken()
  });
  return EMPTY;
}

const handleErrors = (
  source: Observable<HttpEvent<unknown>>,
  urlPath: string
) => {
  return source.pipe(
    catchError(err => {
      console.log(err.error.headers.statusCode)
      if (err.error.headers.statusCode === 401) {
        handle401();
        return EMPTY;
      }
      // rethrow error
      return throwError(() => err);
    })
  )
}

