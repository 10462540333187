import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { selectIsLoggedIn, selectIsUserOnboarded } from '../store/auth.selectors';

export const authGuard: CanActivateFn = (route, state) => {

  const store = inject(Store);
  const router = inject(Router);

  return combineLatest([
    store.select(selectIsLoggedIn),
    store.select(selectIsUserOnboarded)
  ]).pipe(
    take(1),
    mergeMap(([isLoggedIn, isOnboarded]) => {
      if (!isLoggedIn) {
        router.navigate(['/auth/signin']);
        return EMPTY;
      }
      // if (!isOnboarded) {
      //   console.log("isOnb", isOnboarded);
        
      //   router.navigate(['/auth/onboarding'], { queryParams: { returnUrl: state.url } });
      //   return EMPTY;
      // }

      return of(isLoggedIn);
    })
  );
};
