import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './common-pages/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './common-pages/privacy-policy/privacy-policy.component';
import { CustomerAgreementComponent } from './common-pages/customer-agreement/customer-agreement.component';
import { noAuthGuard } from './auth/guard/no-auth.guard';
import { authGuard } from './auth/guard/auth.guard';
import { provideState } from '@ngrx/store';
import { formFeature } from './forms/my-forms/store/form.reducers';
import { provideEffects } from '@ngrx/effects';
import { FormEffects } from './forms/my-forms/store/form.effects';

export const routes: Routes = [
    { path: '', redirectTo: 'forms', pathMatch: 'full' },
    { path: 'auth', loadChildren: () => import('./auth/routes').then(mod => mod.AuthRoutes), canActivate: [noAuthGuard] },
    { path: 'onboarding', loadChildren: () => import('./auth/routes').then(mod => mod.OnboardingRoutes), canActivate: [authGuard] },
    // { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard] },
    {
        path: 'forms',
        loadChildren: () => import('./forms/routes').then(mod => mod.FormsRoutes),
        canActivate: [authGuard],
        providers: [
            provideState(formFeature),
            provideEffects([FormEffects]),
        ]
    },
    {
        path: 'accounts',
        loadChildren: () => import('./accounts/routes').then(mod => mod.AccountRoutes),
        canActivate: [authGuard],
    },
    { path: 'privacy', component: PrivacyPolicyComponent },
    { path: 'customer-agreement', component: CustomerAgreementComponent },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' }
];
