import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthFacade } from './auth/store/auth.facade';
import { LayoutComponent } from './ui-components/layout/layout.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'nexus-console';
  
  constructor(private authFacade: AuthFacade) {}

  isOnboarded$ = this.authFacade.isOnboarded$;

  ngOnInit(): void {
    this.authFacade.loadTokenFromStorage();
  }
}
