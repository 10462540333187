import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormService } from "../form.service";
import * as FormActions from "./form.actions";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { map } from "rxjs/internal/operators/map";
import { catchError, of } from "rxjs";
import { Action } from "@ngrx/store";


@Injectable()
export class FormEffects {

    constructor(
        private actions$: Actions, 
        private formService: FormService
    ) { }

    addForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.addForm),
            switchMap(req =>
                this.formService.addForm(req).pipe(
                    switchMap(response => {
                        const actions: Action[] = [];
                        if ("recordId" in response) {
                            const addFormSuccess = FormActions.addFormSuccess(response);
                            actions.push(addFormSuccess);
                            if (req.formType === 'generic') {
                                let payload = {
                                    formId:response?.recordId,
                                    backgroundColor: "#fff",
                                    displayFont: "DM-Serif Display; serif;",
                                    labelColor: "#595959",
                                    primaryColor: "#003A8C",
                                    primaryFont: "Archivo; sans-serif;",
                                    textColor: "#434343",
                                    formHeading: req?.formName, 
                                    formSubHeading: "Sample Sub Heading",
                                    submitButtonLabel: "Submit"
                                }
                                const updateFormDisplayConfig = FormActions.updateFormConfig(payload);
                                actions.push(updateFormDisplayConfig);
                            }
                        } else {
                            const addFormFailed = FormActions.addFormFailed(response);
                            actions.push(addFormFailed);
                        }
                        return actions;
                    }),
                    catchError((error) => of(FormActions.addFormFailed(error.error)))
                )
            )
        )
    })

    duplicateForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.duplicateForm),
            switchMap(req =>
                this.formService.duplicateForm(req).pipe(
                    map(response => {
                        if ("recordId" in response) {
                            return FormActions.duplicateFormSuccess(response);
                        } else {
                            return FormActions.duplicateFormFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.duplicateFormFailed(error.error)))
                )
            )
        )
    })

    addFormSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.addFormSuccess, FormActions.duplicateFormSuccess),
            map(response => {
                return FormActions.loadForm({ formId: response.recordId });
            })
        )
    })

    updateForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.updateForm),
            switchMap(req =>
                this.formService.updateForm(req).pipe(
                    map(response => {
                        if ("recordInfo" in response) {
                            return FormActions.updateFormSuccess(response);
                        } else {
                            return FormActions.updateFormFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.updateFormFailed(error.error)))
                )
            )
        )
    })

    updateFormDisplayConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.updateFormConfig),
            switchMap(req =>
                this.formService.updateFormDisplayConfig(req).pipe(
                    map(response => {
                        if (response.headers.statusCode === 200) {
                            return FormActions.updateFormConfigSuccess(response?.headers);
                        } else {
                            return FormActions.updateFormConfigFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.updateFormConfigFailed(error.error)))
                )
            )
        )
    })

    deleteForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.deleteForm),
            switchMap(req =>
                this.formService.deleteForm(req.id).pipe(
                    map(response => {
                        if (response.headers.statusCode === 200) {
                            return FormActions.deleteFormSuccess({ id: req.id });
                        } else {
                            return FormActions.deleteFormFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.deleteFormFailed(error.error)))
                )
            )
        )
    })

    LoadForms$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.loadForms),
            switchMap(() =>
                this.formService.getForms().pipe(
                    map(response => {
                        if ("data" in response) {
                            return FormActions.loadFormsSuccess(response);
                        } else {
                            return FormActions.loadFormsFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.loadFormsFailed(error.error)))
                )
            )
        )
    })

    LoadForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FormActions.loadForm),
            switchMap(req =>
                this.formService.getForm(req.formId).pipe(
                    map(response => {
                        if ("recordInfo" in response) {
                            return FormActions.loadFormSuccess(response);
                        } else {
                            return FormActions.loadFormFailed(response);
                        }
                    }),
                    catchError((error) => of(FormActions.loadFormFailed(error.error)))
                )
            )
        )
    })

}